import React from 'react'
import css from './Footer.module.css'
import Logo from "../../assets/logo.png"
import{
    InboxIcon,
    PhoneIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon,
    LocationMarkerIcon,
} from "@heroicons/react/outline"

const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
        <hr />

        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={Logo} alt="" />
                <span>GOD</span>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact Us</span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon} />
                        <span>12-11-kotagalli,nizamabad</span>
                    </span>
                    <span className={css.pngLine}>
                        <PhoneIcon className={css.icon} />
                        <span>+91-7396204776</span>
                    </span>
                    <span className={css.pngLine}>
                        <InboxIcon className={css.icon} />
                        <span>parsiharshith002@gmail.com</span>
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account</span>
                    <span className={css.pngLine}>
                        <LoginIcon className={css.icon} />
                        <span>12-11-kotagalli,nizamabad</span>
                    </span>
                    
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.pngLine}>
                        <UsersIcon className={css.icon} />
                        <span>12-11-kotagalli,nizamabad</span>
                    </span>
                    
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon} />
                        <span>12-11-kotagalli,nizamabad</span>
                    </span>
                    
                </div>
            </div>
        </div>
        <div className={css.copyright}>
            <span>CopyRights &copy;2022 bt GOD, Inc. </span>
            <span>All rights reserved</span>
        </div>
    </div>
  )
}

export default Footer
